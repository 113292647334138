import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import Img from 'gatsby-image';

import mq from '../style/mq';
import Fade from './Fade';
import { bz, font, colors, sliderHeight } from '../consts/style';
import SliderControls from './SliderControls';

const Wrapper = styled.div`
  margin-bottom: 14.4rem;
  ${mq.mobile`
  margin-bottom: 9rem;
  `}
`;

const ModuleWrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  ${mq.tablet`
    flex-wrap: wrap;
  `}
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  ${sliderHeight};
  ${mq.tablet`
  width: 81.4%;
  `}
  ${mq.mobile`
  width: calc(100% - 1.6rem);
  `}
`;

const Image = styled(Img)`
  transition: width 1s;
  position: absolute !important;
  top: 0;
  right: ${(props) => props.active && '0'};
  left: ${(props) => !props.active && '0'};
  width: ${(props) => (props.active ? '100%' : '0%')};
  ${bz};
  z-index: 0;
  ${sliderHeight};
  & > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }
  &::before {
    content: '';
    background: rgba(0, 0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`;

const ControlWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  ${mq.tablet`
  width: 48rem;
  margin-top: 2.4rem;
  `}
  ${mq.mobile`
  width: 100%;
  `}
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  .wrap {
    ${mq.mobile`
      width: 100%;
    `}
    .title {
      ${font.h2};
      margin: 4rem 0;
      padding-right: 6rem;
      ${mq.tablet`
      margin: 2.8rem 0;
      padding-right: 0rem;
      `}
    }
    .body {
      ${font.p};
    }

    ${mq.mobile`
    flex-wrap: wrap;
    padding-left: 0;
    .title, .body {
      width: 100%;
    }
    .title {
      margin-bottom: 1.2rem;
    }
  `}
  }
`;

const Info = styled.div`
  width: 44rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-shrink: 0;
  padding: 0 4em;
  ${mq.tablet`
  justify-content: center;
  padding: 0 1.6em;
  width: 100%;
  align-items: center;
  order: 1;
  `}
`;

const Inner = styled.div`
  width: 100%;
  ${mq.tablet`
    display: flex;
    justify-content: center;
    .infoInner {
      margin: auto;
      width: 66.66%;
    }
  `}
  ${mq.mobile`
  .infoInner {
      margin: auto;
      width: 100%;
    }
  `}
`;
export default function PageSliderLeft({ sliderData, title, body }) {
  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <Wrapper>
      <Fade>
        <ModuleWrapper>
          <ImageWrapper>
            {_map(sliderData, (slide, imageIndex) => (
              <Image
                backgroundColor={colors.blue}
                active={imageIndex === slideIndex}
                key={imageIndex}
                fluid={slide.image}
              />
            ))}
          </ImageWrapper>
          <Info>
            <Inner>
              <Fade>
                <div className="infoInner">
                  <ControlWrapper>
                    <SliderControls
                      slideIndex={slideIndex}
                      setIndex={setSlideIndex}
                      length={sliderData.length}
                    />
                  </ControlWrapper>
                  <ContentWrapper>
                    <div className="wrap">
                      <div className="title">{title}</div>
                      <div className="body">{body}</div>
                    </div>
                  </ContentWrapper>
                </div>
              </Fade>
            </Inner>
          </Info>
        </ModuleWrapper>
      </Fade>
    </Wrapper>
  );
}

PageSliderLeft.propTypes = {
  sliderData: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};
