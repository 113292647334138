import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SEO from '../components/SEO';
import PageWrapper from '../containers/PageWrapper';
import PageHeading from '../components/PageHeading';

import { lifestyle } from '../consts/data';
import PageHeroSlider from '../components/PageHeroSlider';
import PageSliderRight from '../components/PageSliderRight';
import PageSliderLeft from '../components/PageSliderLeft';
import InfoBlock from '../components/InfoBlock';
import Quote from '../components/Quote';

const lifeQuery = graphql`
  {
    Lifestyle_Carousel1_Golf_01: file(
      relativePath: { eq: "Lifestyle_Carousel1_Golf_01.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Lifestyle_Carousel1_Golf_02: file(
      relativePath: { eq: "Lifestyle_Carousel1_Golf_02.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Lifestyle_Carousel1_Golf_03: file(
      relativePath: { eq: "Lifestyle_Carousel1_Golf_03.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Lifestyle_Carousel1_Golf_04: file(
      relativePath: { eq: "Lifestyle_Carousel1_Golf_04.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Lifestyle_Carousel1_Golf_05: file(
      relativePath: { eq: "Lifestyle_Carousel1_Golf_05.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Lifestyle_Carousel2_Amenities_01: file(
      relativePath: { eq: "Lifestyle_Carousel2_Amenities_01.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Lifestyle_Carousel2_Amenities_02: file(
      relativePath: { eq: "Lifestyle_Carousel2_Amenities_02.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Lifestyle_Carousel2_Amenities_03: file(
      relativePath: { eq: "Lifestyle_Carousel2_Amenities_03.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Lifestyle_Carousel2_Amenities_04: file(
      relativePath: { eq: "Lifestyle_Carousel2_Amenities_04.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Lifestyle_Carousel2_Amenities_05: file(
      relativePath: { eq: "Lifestyle_Carousel2_Amenities_05.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Lifestyle_Carousel3_Dining_01: file(
      relativePath: { eq: "Lifestyle_Carousel3_Dining_01.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Lifestyle_Carousel3_Dining_02: file(
      relativePath: { eq: "Lifestyle_Carousel3_Dining_02.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Lifestyle_Carousel3_Dining_03: file(
      relativePath: { eq: "Lifestyle_Carousel3_Dining_03.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Lifestyle_Carousel3_Dining_04: file(
      relativePath: { eq: "Lifestyle_Carousel3_Dining_04.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Lifestyle_Carousel4_Grounds_01: file(
      relativePath: { eq: "Lifestyle_Carousel4_Grounds_01.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Lifestyle_Carousel4_Grounds_02: file(
      relativePath: { eq: "Lifestyle_Carousel4_Grounds_02.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Lifestyle_Carousel4_Grounds_03: file(
      relativePath: { eq: "Lifestyle_Carousel4_Grounds_03.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Lifestyle_Carousel4_Grounds_04: file(
      relativePath: { eq: "Lifestyle_Carousel4_Grounds_4.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const Lifestyle = () => {
  const data = useStaticQuery(lifeQuery);

  // Slider 1 data
  const slider1data = lifestyle.slider1.data;
  slider1data[0].image = data[slider1data[0].file].childImageSharp.fluid;
  slider1data[1].image = data[slider1data[1].file].childImageSharp.fluid;
  slider1data[2].image = data[slider1data[2].file].childImageSharp.fluid;
  slider1data[3].image = data[slider1data[3].file].childImageSharp.fluid;
  slider1data[4].image = data[slider1data[4].file].childImageSharp.fluid;

  const slider2data = lifestyle.slider2.data;
  slider2data[0].image = data[slider2data[0].file].childImageSharp.fluid;
  slider2data[1].image = data[slider2data[1].file].childImageSharp.fluid;
  slider2data[2].image = data[slider2data[2].file].childImageSharp.fluid;
  slider2data[3].image = data[slider2data[3].file].childImageSharp.fluid;
  slider2data[4].image = data[slider2data[4].file].childImageSharp.fluid;

  const slider3data = lifestyle.slider3.data;
  slider3data[0].image = data[slider3data[0].file].childImageSharp.fluid;
  slider3data[1].image = data[slider3data[1].file].childImageSharp.fluid;
  slider3data[2].image = data[slider3data[2].file].childImageSharp.fluid;
  slider3data[3].image = data[slider3data[3].file].childImageSharp.fluid;

  const slider4data = lifestyle.slider4.data;
  slider4data[0].image = data[slider4data[0].file].childImageSharp.fluid;
  slider4data[1].image = data[slider4data[1].file].childImageSharp.fluid;
  slider4data[2].image = data[slider4data[2].file].childImageSharp.fluid;
  slider4data[3].image = data[slider4data[3].file].childImageSharp.fluid;

  return (
    <PageWrapper>
      <SEO
        title="Lifestyle | A Triple Five-Star Canvas | Penrose Grand Del Mar"
        description="Penrose and its exclusive offerings are made possible by the grounds on which they stand, the illustrious Grand Del Mar."
      />
      <PageHeading
        title={lifestyle.heading.title}
        body={lifestyle.heading.body}
      />
      <PageHeroSlider
        title={lifestyle.slider1.content.title}
        body={lifestyle.slider1.content.body}
        sliderData={slider1data}
      />
      <InfoBlock
        body={lifestyle.infoBlock.body}
        slides={lifestyle.infoBlock.slides}
      />
      <PageSliderLeft
        title={lifestyle.slider2.content.title}
        body={lifestyle.slider2.content.body}
        sliderData={slider2data}
      />
      <Quote body={lifestyle.quote.body} author={lifestyle.quote.author} />
      <PageSliderRight
        title={lifestyle.slider3.content.title}
        body={lifestyle.slider3.content.body}
        sliderData={slider3data}
      />
      <PageSliderLeft
        title={lifestyle.slider4.content.title}
        body={lifestyle.slider4.content.body}
        sliderData={slider4data}
      />
    </PageWrapper>
  );
};

export default Lifestyle;
