import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import useWindowSize from '@rehooks/window-size';
import Img from 'gatsby-image';

import mq from '../style/mq';
import Fade from './Fade';
import { bz, font, colors, sliderHeight, headingOffset } from '../consts/style';
import SliderControls from './SliderControls';

const Wrapper = styled.div`
  margin-bottom: 14.4rem;
  ${mq.mobile`
  margin-bottom: 9rem;
  `}
`;

const Carousel = styled.div`
  height: 51rem;
  width: 100%;
  position: relative;
  overflow: hidden;
  ${sliderHeight};
`;

const ImageWrapper = styled.div`
  position: absolute;
  height: 51rem;
  top: 0;
  width: ${(props) => props.width}px;
  right: ${(props) => props.right}px;
  ${sliderHeight};
  ${mq.tabletSmall`
    width: 83.33%;
    right: 0 !important;
    &:last-child {
      display: none;
    }
  `}
  ${mq.mobile`
  width: calc(100% - 1.6rem) !important;
  right: 1.6rem !important;
  `}
`;

const Image = styled(Img)`
  transition: width 1s;
  position: absolute !important;
  top: 0;
  right: ${(props) => props.active && '0'};
  left: ${(props) => !props.active && '0'};
  width: ${(props) => (props.active ? '100%' : '0%')};
  ${bz};
  z-index: 0;
  ${sliderHeight};
  & > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }
  &::before {
    content: '';
    background: rgba(0, 0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`;

const ControlWrapper = styled.div`
  padding: 2.4rem 1.6rem 3.2rem;
  display: flex;
`;

const ContentWrapper = styled.div`
  padding: 0 1.6rem;
  ${mq.tabletSmall`
      justify-content: flex-end;
  `}
  .wrap {
    max-width: 100%;
    .title {
      ${font.h2};
      ${headingOffset};
      ${mq.mobile`
      padding-bottom: 1.6rem;
    `}
    }
    .body {
      ${font.p};
    }
  }
`;

export default function PageHeroSlider({ sliderData, title, body }) {
  const [slideIndex, setSlideIndex] = useState(0);
  const [innerWidth, setInnerWidth] = useState(1000);
  const screen = typeof window !== 'undefined' ? useWindowSize() : null;

  useEffect(() => {
    if (typeof window !== 'undefined') setInnerWidth(window.innerWidth);
  }, [screen]);

  function getImageWidth() {
    return innerWidth - 230;
  }

  function getRightValue(index) {
    if (index === 0) return 117;
    return 117 - index * 24 - index * getImageWidth();
  }

  function isImageActive(c, i, s) {
    if (c === 0) {
      return i === s;
    }
    if (c === 1 && s !== sliderData.length - 1) {
      return i === s + 1;
    }
    return i === 0;
  }

  return (
    <Fade>
      <Wrapper>
        <Carousel>
          {_map(new Array(2), (_noop, containerIndex) => {
            return (
              <ImageWrapper
                key={containerIndex}
                width={getImageWidth()}
                right={getRightValue(containerIndex)}
              >
                {_map(sliderData, (slide, imageIndex) => (
                  <Image
                    active={isImageActive(
                      containerIndex,
                      imageIndex,
                      slideIndex
                    )}
                    index={imageIndex}
                    key={imageIndex}
                    fluid={slide.image}
                    backgroundColor={colors.blue}
                  />
                ))}
              </ImageWrapper>
            );
          })}
        </Carousel>
        <Fade>
          <ControlWrapper className="row jcc">
            <div className="controls col-8 col-t-8 col-ts-8 col-m-12">
              <SliderControls
                slideIndex={slideIndex}
                setIndex={setSlideIndex}
                length={sliderData.length}
              />
            </div>
          </ControlWrapper>
          <ContentWrapper className="row jcc">
            <div className="contentWrap col-8 col-t-8 col-ts-10 offset-ts-2 col-m-12">
              <div className="wrap row">
                <div className="title col-6 col-t-5 col-ts-5 col-m-12">
                  {title}
                </div>
                <div className="body col-6 col-t-7 col-ts-7 col-m-12">
                  {body}
                </div>
              </div>
            </div>
          </ContentWrapper>
        </Fade>
      </Wrapper>
    </Fade>
  );
}

PageHeroSlider.propTypes = {
  sliderData: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};
