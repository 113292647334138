import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _map from 'lodash/map';

import mq from '../style/mq';
import { fadeInUp } from '../style/animations';
import Fade from './Fade';
import { bz, font, colors, fontOffset } from '../consts/style';

const Wrapper = styled.div`
  margin-bottom: 14.4rem;
  ${mq.mobile`
  margin-bottom: 9rem;
  flex-wrap: wrap;
  `}
  display: flex;
`;

const QuoteBlock = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.6rem;
  span {
    ${font.h3}
  }
  .inner {
    text-align: center;
  }
  ${mq.mobile`
    width: 100%;
    margin-bottom: 8rem;
  `}
`;
const SlideBlock = styled.div`
  padding: 6rem 3.2rem;
  width: 50%;
  background: ${colors.grey};
  display: flex;
  justify-content: center;
  align-items: center;
  ${mq.desktop_up`
    height: 45vw;
  `}
  ${mq.mobile`
  width: 100%;
  padding: 4rem 1.6rem;
  `}
  p {
    margin: 0;
  }
  .title {
    ${font.h3}
  }
  ul {
    padding-left: 2.6rem;
    list-style: none;
    margin: 4rem 0;
    min-height: 28rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${mq.tablet`
    min-height: 31.1rem;
  `}
    ${mq.tabletSmall`
      min-height: 34rem;
    `}
    ${mq.mobile`
    height: 35rem;
    margin: 1rem 0;
    `}
    span {
      ${fontOffset};
    }
    li {
      transition: 0.5s all;
      position: relative;
      margin-bottom: 3.2rem;
      animation: ${fadeInUp} 0.5s;
      ${font.p};
      ${bz};
      &:after {
        content: '';
        height: 0.4rem;
        width: 0.4rem;
        border-radius: 50%;
        background: black;
        position: absolute;
        top: 1rem;
        left: -2.4rem;
      }
    }
  }
`;

const SlideWrapper = styled.div`
  max-width: 100%;
  width: 83.33%;
  margin: 0 auto;
  ${mq.mobile`
    width: 100%;
  `}
`;

const Controls = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Dot = styled.button`
  margin-right: 2.4rem;
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 50%;
  padding: 0;
  background: ${(props) => (props.selected ? colors.orange : 'white')};
  border: 1px solid ${(props) => (props.selected ? colors.orange : 'white')};
  &:last-child {
    margin: 0;
  }
`;

export default function InfoBlock({ slides, body }) {
  const [slideIndex, setSlideIndex] = useState(0);

  function renderPages() {
    return _map(slides, (slide, si) => {
      return _map(slide, (s, i) =>
        si === slideIndex ? (
          <li key={`${si}-${i}`}>
            <span>{s}</span>
          </li>
        ) : null
      );
    });
  }
  function renderDots() {
    return _map(slides, (_noop, i) => {
      return (
        <Dot
          selected={i === slideIndex}
          key={i}
          onClick={() => setSlideIndex(i)}
        />
      );
    });
  }

  return (
    <Fade>
      <Wrapper>
        <QuoteBlock>
          <Fade>
            <div className="inner row jcc">
              <div className="col-8 col-t-8 col-ts-8">
                <span>{body}</span>
              </div>
            </div>
          </Fade>
        </QuoteBlock>
        <SlideBlock>
          <Fade>
            <SlideWrapper>
              <p className="num">0{slideIndex + 1}</p>
              <p className="title">Course Features</p>
              <ul>{renderPages()}</ul>
            </SlideWrapper>
            <Controls>{renderDots()}</Controls>
          </Fade>
        </SlideBlock>
      </Wrapper>
    </Fade>
  );
}

InfoBlock.propTypes = {
  slides: PropTypes.array.isRequired,
  body: PropTypes.string.isRequired,
};
