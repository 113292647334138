import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { font } from '../consts/style';
import Fade from './Fade';
import mq from '../style/mq';

const Wrapper = styled.div`
  margin-bottom: 14.4rem;
  ${mq.mobile`
  margin-bottom: 9rem;
  `}
  max-width: 100vw;
  width: 100%;
  padding: 0 1.6rem;
  text-align: center;
  padding-top: ${(props) => props.compact && '0'};
  .content {
    width: 83.4rem;
    ${mq.tablet`
      width: 64rem;
    `}
    max-width: 100%;
  }
  h1 {
    ${font.h2};
    margin-bottom: 2.8rem;
  }
  h4 {
    ${font.pCaps};
  }
`;

export default function Quote({ author, body }) {
  return (
    <Fade>
      <Wrapper className="row jcc">
        <div className="content">
          <h1>{body}</h1>
          <h4>- {author}</h4>
        </div>
      </Wrapper>
    </Fade>
  );
}

Quote.propTypes = {
  author: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};
